import React from "react";

import IndividualAcademic from "./IndividualAcademic";
import Listing from "../Listing/Listing";

const AcademicListing = () => {
  const academics = [
    {
      institution: "Temasek Polytechnic",
      degree: "Diploma (with Merit) in Information Technology",
      from: "2017",
      to: "2020",
      certifications: ["Diploma Plus Certificate in Psychology Fundamentals"],
      achievements: ["Director's List for All Applicable Semesters"],
      activities: ["2017 - 2020: Temasek Polytechnic Dance Ensemble Member"],
      hackathons: []
    },
    {
      institution: "Singapore Management University",
      degree: "Bachelor of Science (Software Engineering)",
      from: "2022",
      to: "Current",
      certifications: [
        "AWS Certified Solutions Architect - Associate",
        "AWS Certified Developer - Associate"
      ],
      achievements: [
        "Dean's List for all applicable semesters",
        "2023: SMU Ellipsis Code# | 14th out of 463 participants",
        "2023: SMU Ellipsis TicTacCode | Finalist",
        "2024: SMU VMware Tanzu Award 2024 for Enterprise Solution Management | Winner",
        "2024: SMU VMware Tanzu Award 2024 for Enterprise Solution Development | Winner",
      ],
      activities: [
        "2022 - Current: IMDA SG Digital Young Leader",
        "2023 - 2024: SMU Coder’s Assembly Mentor",
        "2022 - 2023: SMU Project HeartCode Mentor",
        "2023 - 2024: SMU Project HeartCode Leader",
      ],
      hackathons: [
        "2024: Dell InnovateFest | Finalist",
        "2024: GIC #CODETOIMPACT | Participant",
        "2024: TikTok TechJam | Participant",
        "2023: SMU Ellipsis Tic Tac Code | Finalist",
        "2023: SMU Ellipsis Code# | Placed 14th out of 463 participants"
      ]
    },
  ];

  return (
    <Listing
      list={academics}
      title={"Academics"}
      Component={IndividualAcademic}
    />
  );
};

export default AcademicListing;
