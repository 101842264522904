import React, { useState, useEffect, useCallback } from "react";
import { Element } from "react-scroll";

import Sidebar from "../Sidebar/Sidebar";
import ProjectListing from "../ProjectListing/ProjectListing";
import ExperienceListing from "../ExperienceListing/ExperienceListing";
import AcademicListing from "../AcademicListing/AcademicListing";
import Contact from "../Contact/Contact";

const Content = () => {
  const [revealSidebar, setRevealSidebar] = useState(false);

  const checkScrollTop = useCallback(() => {
    if (!revealSidebar && window.scrollY > window.innerHeight * 0.8) {
      setRevealSidebar(true);
    } else if (revealSidebar && window.scrollY <= window.innerHeight * 0.8) {
      setRevealSidebar(false);
    }
  }, [revealSidebar, setRevealSidebar]);

  useEffect(() => {
    window.addEventListener("scroll", checkScrollTop);
    return () => window.removeEventListener("scroll", checkScrollTop);
  }, [checkScrollTop]);

  return (
    <div className="bg-gray-50">
      <div className="container mx-auto flex">
        <Sidebar reveal={revealSidebar} />
        <div className="flex-grow">
        <Element name="experiences">
            <ExperienceListing />
          </Element>
          <hr className="mt-10 h-px border-none bg-gray-300" />
          <Element name="projects">
            <ProjectListing />
          </Element>
          <hr className="mt-10 h-px border-none bg-gray-300" />
          <Element name="academic">
            <AcademicListing />
          </Element>
          <hr className="mt-10 h-px border-none bg-gray-300" />
          <Element name="contact">
            <Contact />
          </Element>
        </div>
      </div>
    </div>
  );
};

export default Content;
