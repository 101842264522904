import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-scroll";
import { Typography } from "@material-tailwind/react";

import sgFlag from "../../assets/sg_flag.jpg";
import SocialIcons from "../SocialIcons/SocialIcons";
import "./Sidebar.css";

const SidebarLink = ({ to, activeSection, setActiveSection }) => (
  <Link
    to={to}
    spy={true}
    smooth={true}
    duration={500}
    onSetActive={() => setActiveSection(to)}
    className={`
    sidebar-link cursor-pointer uppercase hover:font-semibold
    ${activeSection === to ? "active-link text-gray-900" : "text-gray-700"}  
      `}
  >
    {to}
  </Link>
);

SidebarLink.propTypes = {
  to: PropTypes.string.isRequired,
  activeSection: PropTypes.string.isRequired,
  setActiveSection: PropTypes.func.isRequired,
};

const Sidebar = ({ reveal }) => {
  const sections = ["home", "experiences", "projects", "academic"];
  const [activeSection, setActiveSection] = useState(sections[0]);

  return (
    <div
      className={`
        sticky top-20 mt-20 flex
        hidden h-screen flex-col
        items-start justify-between overflow-auto
        transition-opacity duration-500
        ${reveal ? "opacity-100" : "opacity-0"}
        lg:flex lg:h-[calc(50vh)] lg:w-1/3

      `}
    >
      <Typography variant="h3" className="text-left">
        Zheng Ting
        <Typography className="w-5/6">
          A Software Engineering undergraduate from Singapore.{" "}
          <img className="flag" src={sgFlag} alt="Singapore flag." />
        </Typography>
      </Typography>
      <div className="flex flex-col items-start">
        {sections.map((section) => (
          <SidebarLink
            key={section}
            to={section}
            activeSection={activeSection}
            setActiveSection={setActiveSection}
          />
        ))}
      </div>
      <SocialIcons />
    </div>
  );
};

Sidebar.propTypes = {
  reveal: PropTypes.bool.isRequired,
};

export default Sidebar;
