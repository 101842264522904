import React from "react";

import IndividualProject from "./IndividualProject";
import EduhelperThumbnail from "../../assets/eduhelper-thumbnail.jpg";
import C4SRabbitThumbnail from "../../assets/c4srabbit-thumbnail.jpg";
import AlgorithmVisualiserThumbnail from "../../assets/algorithmvisualiser-thumbnail.jpg";
import EthereumTransactionProcessorThumbnail from "../../assets/ethereumtransactionprocessor-thumbnail.png";
import Listing from "../Listing/Listing";

const ProjectListing = () => {
  const projects = [
    {
      thumbnailSrc: AlgorithmVisualiserThumbnail,
      projectTitle: "Sorting Visualiser",
      projectUrl: "https://ztdevelops.github.io/algorithm-visualiser/",
      projectDescription:
        "Static web application for visualising sorting algorithms, providing a visual representation of the sorting process to aid in understanding and learning of popular sorting algorithms.",
      projectStack: ["React", "JavaScript"],
    },
    {
      thumbnailSrc: C4SRabbitThumbnail,
      projectTitle: "C4SRabbit",
      projectUrl: null,
      projectDescription:
        "All-in-one centralised tool for Singapore Management University students for all matters regarding to community service projects, enhancing management efficiency and streamlining processes.",
      projectStack: ["React", "ChakraUI", "Express", "Docker", "MySQL"],
    },
    {
      thumbnailSrc: EduhelperThumbnail,
      projectTitle: "EduHelper",
      projectUrl: "https://github.com/ztdevelops/EduHelper",
      projectDescription:
        "Web application focused on democratising education by providing a platform for students to share and access educational resources, utilising AI to generate flashcards and MCQs from notes to reinforce learning.",
      projectStack: ["gRPC", "Kubernetes", "Go", "Python", "AWS", "RabbitMQ"],
    },
    {
      thumbnailSrc: EthereumTransactionProcessorThumbnail,
      projectTitle: "Ethereum Transaction Processor",
      projectUrl: "https://github.com/ztdevelops/ethereum-transaction-processor",
      projectDescription:
        "Backend system for processing Ethereum blockchain transactions, both in real-time and historical data, and integrates with external blockchain-related services to enrich transaction details, allowing for efficient querying and analysis of processed data.",
      projectStack: ["Docker", "Kafka", "FastAPI", "MongoDB", "WebSockets"],
    },
  ];

  return (
    <>
      <Listing
        list={projects}
        title={"Projects"}
        Component={IndividualProject}
      />
    </>
  );
};

export default ProjectListing;
