import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@material-tailwind/react";

const List = ({ title, items }) => (
  items.length > 0 && (
    <Typography color="gray" className="mb-4">
      {title}:
      <ul className="lg:list-inside lg:list-disc">
        {items.map((item) => (
          <li
            key={item}
            className="
              transform transition-all duration-200
              hover:scale-105 hover:text-gray-900
            "
          >
            {item}
          </li>
        ))}
      </ul>
    </Typography>
  )
);

List.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const IndividualAcademic = ({
  institution,
  degree,
  from,
  to,
  certifications,
  achievements,
  activities,
  hackathons
}) => (
  <div className="justify-content-between mt-5 flex flex-col lg:text-left">
    <Typography
      color="gray"
      variant="h3"
      className="
            transform
            transition-all duration-200 hover:text-black"
    >
      {institution}
    </Typography>
    <Typography variant="h5" color="gray">
      {degree}
    </Typography>
    <Typography color="gray" className="mb-4">
      {from} - {to}
    </Typography>

    <List items={certifications} title="Notable certifications" />
    <List items={achievements} title="Notable achievements" />
    <List items={activities} title="Academic activities" />
    <List items={hackathons} title="Hackathons" />
  </div>
);

IndividualAcademic.propTypes = {
  institution: PropTypes.string.isRequired,
  degree: PropTypes.string.isRequired,
  from: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  certifications: PropTypes.arrayOf(PropTypes.string).isRequired,
  achievements: PropTypes.arrayOf(PropTypes.string).isRequired,
  activities: PropTypes.arrayOf(PropTypes.string).isRequired,
  hackathons: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default IndividualAcademic;
